

<template>
  <div class="stop" @click="scrollTop">
    <img src="../../../assets/images/icon/common/scroll-top.svg" alt="">
  </div>

</template>
<script>
export default {
  name: "ScrollToTop",
  methods:{
    scrollTop() {
      this.$bus.$emit('scroll2top');
    }
  }
}
</script>
<style scoped>
  .stop{
    position: fixed;
    bottom: 8rem;
    right: 40rem;
    border-radius: 50%;
    background-color: var(--sportsscrolltopbg);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;width: 5rem;height: 5rem;
    cursor: pointer;
  }
  .stop img{
    width: 3.5rem;height: 3.5rem
  }
  @media screen and (max-width: 1024px) {
    .stop{
      bottom: 8rem;
      right: 1.5rem;
      width: 3rem;height: 3rem
    }
    .stop img{
      width: 2.5rem;height: 2.5rem
    }
  }
</style>